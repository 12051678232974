body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

.navbar.scrolled {
  background-color: rgba(21, 21, 21, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
  color: #fff;
  transition: transform 0.3s ease;
}

.navbar-brand:hover {
  transform: scale(1.05);
}

.navbar-menu {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navbar-menu li a {
  color: #fff;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;
}

.navbar-menu li a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navbar-menu li a:hover::after {
  transform: scaleX(1);
}

.navbar-lang {
  color: #fff;
  transition: opacity 0.3s ease;
}

.navbar-lang:hover {
  opacity: 0.8;
}

.page-content {
  padding-top: 0;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.home {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
}

.hero {
  background-image: url('../public/headerDark.jpg');
  background-size: cover;
  background-position: center;
  text-align: left;
  padding: 4rem 0;
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-content {
  text-align: left;
  width: 100%;
  padding: 4rem 5vw;
  max-width: 1200px;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  color: #f1f2f4;
  animation: fadeInLeft 0.8s ease-out forwards;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dark-bg-section {
  background-color: #151515;
  color: #f1f2f4;
}

.alt-dark-bg-section {
  background-color: #262626;
  color: #e0e0e0;
}

.light-bg-section {
  background-color: #737373;
  color: #ffffff;
}

.alt-light-bg-section {
  background-color: #e0e0e0;
  color: #333333;
}

.dark-bg-section,
.alt-dark-bg-section,
.light-bg-section,
.alt-light-bg-section {
  padding: 4rem 0;
}

.section-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.section-content.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.text-content {
  flex: 0 0 50%;
  text-align: left;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.6s ease-out 0.2s, transform 0.6s ease-out 0.2s;
}

.image-content {
  flex: 0 0 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.6s ease-out 0.4s, transform 0.6s ease-out 0.4s;
}

.section-content.is-visible .text-content,
.section-content.is-visible .image-content {
  opacity: 1;
  transform: translateX(0);
}

.image-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  max-height: 500px;
  object-fit: contain;
}

.dark-bg-section h2,
.alt-dark-bg-section h2,
.light-bg-section h2,
.alt-light-bg-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 0;
  color: inherit;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.section-content.is-visible h2 {
  animation: fadeIn 0.5s ease-out 0.2s forwards;
}

.dark-bg-section p,
.alt-dark-bg-section p,
.light-bg-section p,
.alt-light-bg-section p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  color: inherit;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.section-content.is-visible p {
  animation: fadeIn 0.5s ease-out 0.4s forwards;
}

.dark-bg-section ul,
.alt-dark-bg-section ul,
.light-bg-section ul,
.alt-light-bg-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dark-bg-section li,
.alt-dark-bg-section li,
.light-bg-section li,
.alt-light-bg-section li {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  position: relative;
  padding: 1rem 1rem 1rem 3rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeInUp 0.5s ease-out forwards;
  max-width: 80%;
}

.dark-bg-section li::before,
.alt-dark-bg-section li::before,
.light-bg-section li::before,
.alt-light-bg-section li::before {
  content: '✓';
  position: absolute;
  left: 1rem;
  color: #61dafb;
  font-weight: bold;
}

.dark-bg-section li:hover{
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
}

.alt-dark-bg-section li:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
}

.light-bg-section li:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.alt-light-bg-section li:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(86, 85, 85, 0.2);
}

.section-content.is-visible ul li:nth-child(1) { animation: fadeIn 0.5s ease-out 0.6s forwards; }
.section-content.is-visible ul li:nth-child(2) { animation: fadeIn 0.5s ease-out 0.7s forwards; }
.section-content.is-visible ul li:nth-child(3) { animation: fadeIn 0.5s ease-out 0.8s forwards; }

@media (max-width: 1200px) {
  .hero-content,
  .dark-bg-section,
  .light-bg-section,
  .alt-dark-bg-section,
  .alt-light-bg-section,
  .cta-section,
  .footer-content {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media (max-width: 768px) {
  .hero-content,
  .dark-bg-section,
  .light-bg-section,
  .alt-dark-bg-section,
  .alt-light-bg-section,
  .cta-section,
  .footer-content {
    padding: 3rem 3rem !important;
  }

  .hero {
    height: auto;
    min-height: 50vh;
  }

  .hero-content {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  p, .sub-headline {
    font-size: 1rem;
  }

  .section-content {
    flex-direction: column;
    padding: 0 1rem;
  }

  .text-content,
  .image-content {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .image-content {
    margin-top: 2rem;
  }


  .dark-bg-section li,
  .light-bg-section li,
  .alt-dark-bg-section li,
  .alt-light-bg-section li {
    max-width: 100%;
  }

  li {
    font-size: 0.9rem;
    padding: 0.8rem 0.8rem 0.8rem 2.5rem;
    max-width: 100%;
  }

  li::before {
    left: 0.8rem;
  }

  .cta-text {
    font-size: 1.2rem;
  }

  .cta-button,
  .cta-button-primary {
    font-size: 1rem;
    padding: 12px 24px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  p, .sub-headline {
    font-size: 0.9rem;
  }

  li {
    font-size: 0.8rem;
    padding: 0.7rem 0.7rem 0.7rem 2.2rem;
  }

  li::before {
    left: 0.7rem;
  }

  .image-content img {
    max-height: 250px;
  }

  .cta-text {
    font-size: 1rem;
  }

  .cta-button,
  .cta-button-primary {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}

.contact, .privacy {
  background-color: #262626;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem 0 2rem;
}

h1 {
  font-size: 3rem;
  color: #f1f2f4;
  margin-bottom: 2rem;
}

p {
  font-size: 1.2rem;
  color: #f1f2f4;
  max-width: 600px;
  line-height: 1.6;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
}

label {
  color: #f1f2f4;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

input, textarea {
  background-color: transparent;
  border: 1px solid #f1f2f4;
  border-radius: 4px;
  color: #f1f2f4;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

input:focus, textarea:focus {
  outline: none;
  border-color: #61dafb;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

button {
  background-color: transparent;
  color: #f1f2f4;
  border: 2px solid #f1f2f4;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
  align-self: flex-start;
}

button:hover {
  background-color: #f1f2f4;
  color: #262626;
}

.privacy .policy-content {
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.privacy h2 {
  color: #f1f2f4;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.privacy p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.privacy strong {
  font-weight: bold;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-on-load {
  animation: fadeInUp 0.8s ease-out forwards;
}

.hero .sub-headline {
  text-align: left;
  font-size: 1.2rem;
  color: #f1f2f4;
  max-width: 600px;
  line-height: 1.6;
  animation: fadeInRight 0.8s ease-out 0.2s forwards;
  opacity: 0;
}

.cta-button-top {
  display: inline-block;
  background-color: #f5f5f5;
  color: #262626;
  text-decoration: none;
  padding: 12px 24px;
  font-size: 1.2rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  animation: fadeInUp 1.5s ease-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.cta-button-top:hover {
  background-color: #e0e0e0;
  box-shadow: 0 4px 10px rgba(0,0,0,0.15);
  transform: translateY(-2px);
}

.cta-button-top:active {
  transform: translateY(1px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.cta-button-top strong {
  font-weight: bold;
}

.cta-button-top .light-text {
  font-weight: 300;
  opacity: 0.8;
}

.feature-section {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4rem 2rem;
  margin-top: 2rem;
}

.feature-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.feature-section p {
  margin-bottom: 1.5rem;
}

.feature-section ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.feature-section li {
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;
}

.feature-section li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #f5f5f5;
}

.cta-section {
  background-color: #f5f5f5;
  padding: 4rem 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-container {
  margin-top: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 1200px;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.cta-container.is-visible {
  opacity: 1;
  transform: scale(1);
  padding: 2rem; /* Add padding */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
}

.cta-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: inherit;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .cta-button-primary {
    width: 100%;
    box-sizing: border-box;
  }
}

@media (max-width: 480px) {
  .cta-text {
    font-size: 1rem;
  }

  .cta-button-primary {
    font-size: 0.9rem;
    padding: 10px 16px;
  }
}


.global-footer {
  background-color: #151515;
  color: #fff;
  padding: 2rem 5vw;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.global-footer a {
  color: #f5f5f5;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
  font-weight: 400;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
}

.global-footer a:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in-right {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in-left.fade-in,
.fade-in-right.fade-in {
  opacity: 1;
  transform: translateX(0);
}

/* Adjust the existing animations for list items */
.fade-in-left li,
.fade-in-right li {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fade-in-left.fade-in li:nth-child(1),
.fade-in-right.fade-in li:nth-child(1) { transition-delay: 0.2s; }
.fade-in-left.fade-in li:nth-child(2),
.fade-in-right.fade-in li:nth-child(2) { transition-delay: 0.4s; }
.fade-in-left.fade-in li:nth-child(3),
.fade-in-right.fade-in li:nth-child(3) { transition-delay: 0.6s; }

.fade-in-left.fade-in li,
.fade-in-right.fade-in li {
  opacity: 1;
  transform: translateY(0);
}

.alt-light-bg-section .image-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  min-height: 500px;
  object-fit: contain;
}

.faq-item {
  margin-bottom: 2rem;
}

.faq-item h3 {
  font-size: 1.3rem;
  color: #f1f2f4;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  border-bottom: 1px solid rgba(241, 242, 244, 0.2);
}

.faq-item p {
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 0.5rem;
}

.cta-section {
  background-color: #f5f5f5;
  padding: 4rem 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-container {
  margin-top: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 1200px;
  padding: 2rem; /* Add padding */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
}

.cta-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: inherit;
  margin-bottom: 1.5rem; /* Increase bottom margin */
}

.cta-button-primary {
  display: inline-block;
  background-color: #000000;
  border: 2px solid #000000;
  color: white;
  text-decoration: none;
  padding: 14px 28px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  width: auto;
  max-width: 100%;
  margin-bottom: 1rem; /* Add space under the button */
}

.cta-button-primary:hover {
  background-color: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.17);
  color: #000000;
}

.light-text {
  color: #262626; /* Adjust this color to match your design */
  font-weight: 400; 
  background-color: #f1f2f4; 
  line-height: 2;
  padding: 10px 10px;
  border-radius: 4px;
}

.light-text-1 {
  color: #ffffff; /* Adjust this color to match your design */
  font-weight: 400; 
  background-color: #1a1a1c; 
  line-height: 1.5;
  padding: 10px 10px;
  border-radius: 4px;
}


.light-text-2 {
  color: #737373; /* Adjust this color to match your design */
  font-weight: 400; 
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-on-scroll {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.animate-on-scroll.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.hero h1 {
  animation: fadeInLeft 0.8s ease-out forwards;
}

.hero .sub-headline {
  animation: fadeInRight 0.8s ease-out 0.2s forwards;
  opacity: 0;
}

.cta-button-top {
  animation: fadeInUp 0.8s ease-out 0.4s forwards;
  opacity: 0;
}

.section-content {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.section-content.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.section-content h2,
.section-content p,
.section-content ul li {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.section-content.is-visible h2 {
  animation: fadeIn 0.5s ease-out 0.2s forwards;
}

.section-content.is-visible p {
  animation: fadeIn 0.5s ease-out 0.4s forwards;
}

.section-content.is-visible ul li:nth-child(1) { animation: fadeIn 0.5s ease-out 0.6s forwards; }
.section-content.is-visible ul li:nth-child(2) { animation: fadeIn 0.5s ease-out 0.7s forwards; }
.section-content.is-visible ul li:nth-child(3) { animation: fadeIn 0.5s ease-out 0.8s forwards; }

.section-content .text-content {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.6s ease-out 0.2s, transform 0.6s ease-out 0.2s;
}

.section-content .image-content {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.6s ease-out 0.4s, transform 0.6s ease-out 0.4s;
}

.section-content.is-visible .text-content,
.section-content.is-visible .image-content {
  opacity: 1;
  transform: translateX(0);
}

.cta-section .cta-container {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.cta-section .cta-container.is-visible {
  opacity: 1;
  transform: scale(1);
}